import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasDeliveryPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasDeliveryPerms, setHasDeliveryPerms] = useState(true);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.DELIVERY_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.DELIVERY_USER?.length;
      setHasDeliveryPerms(isAuthorized);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasDeliveryPerms, permsLoaded };
};

export default useHasDeliveryPerms;
