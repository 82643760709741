import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  cartDataPointsGroups,
  deleteCartDataPoints,
  getCart,
  globalRoutes,
  publishCartDataPoints,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import { useQueryClient } from '@tanstack/react-query';
import useHasDeliveryPerms from './hooks/useHasDeliveryPerms';
import exampleService from './services/exampleService';
import { appDataPointGroups } from './enums/AppDataPoints';
import env from './env';
import RouteConstants from './routes/RouteConstants';

const AppRouter = lazy(() => import('./routes/AppRouter'));

export default function Root() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const queryClient = useQueryClient();
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.BASE_NAME}`,
        displayName: 'Time Punch Clock Home',
        keywords: ['Time Punch Clock'],
      },
    ],
    [],
  );
  const { hasDeliveryPerms, permsLoaded } = useHasDeliveryPerms();

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.CFA_DELIVERY]: appDataPointGroups,
    });
  }, []);

  useEffect(() => {
    if (permsLoaded && env.REACT_APP_ENVIRONMENT === 'dev') {
      userPermissions.next({
        ...userPermissions.value,
        hasDeliveryAccess: hasDeliveryPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        deliveryLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
    }
  }, [hasDeliveryPerms, permsLoaded, routes]);

  useEffect(() => {
    publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.appName === AppNames.CFA_DELIVERY &&
        selectedStoreNumber.length
      ) {
        exampleService.publishCart(selectedStoreNumber).then(() => {
          getCart(selectedStoreNumber);
          queryClient.clear();
        });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.appName === AppNames.CFA_DELIVERY &&
        selectedStoreNumber.length
      ) {
        exampleService.deleteCart(selectedStoreNumber).then(() => {
          getCart(selectedStoreNumber);
          queryClient.clear();
        });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  return (
    <Suspense fallback={null}>
      <AppRouter />
    </Suspense>
  );
}
