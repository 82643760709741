/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    mainMenu: 'Main Menu',
  },
  Home: {
    cardSubtitle: 'Subtitle',
    cardTitle: 'Card Title',
    title: 'Time Punch Clock Settings',
  },
};
